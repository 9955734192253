<template>
  <base-layout-two page-title="Settings" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Settings</h1>
    </section>
  </base-layout-two>
</template>

<script>
export default {

};
</script>